import { TextureEnum } from './types';
import { Discount } from 'braintree';

export interface RechargeLineItem {
  quantity: number;
  variant_id: string | number | undefined; // string also possible here.
  charge_interval_frequency?: number;
  cutoff_day_of_month?: number; // unsure
  cutoff_day_of_week?: number; // unsure
  expire_after_specific_number_of_charges?: number | null; // unsure
  fulfillment_service?: string;
  grams?: number;
  image?: string;
  line_price?: string;
  order_day_of_month?: number;
  order_day_of_week?: number;
  order_interval_frequency?: number;
  order_interval_unit?: string;
  price?: string;
  product_id?: number | string;
  properties?: null; // unsure, not in docs
  requires_shipping?: boolean;
  sku?: string;
  taxable?: boolean;
  title?: string;
  variant_title?: string;
  vendor?: string;
}

enum DiscountType {
  FIXED_AMOUNT = 'fixed_amount',
  PERCENTAGE = 'percentage',
  SHIPPING = 'shipping'
}

enum ExternalCheckoutSource {
  SHOPIFY = 'shopify'
}

export interface AppliedDiscount {
  amount: string;
  value: number;
  code: string;
  value_type?: DiscountType;
  applicable?: boolean;
  non_applicable_reason?: string;
}

export interface RechargeCheckoutRequest {
  // only two attributes required to create a recharge checkout
  line_items: RechargeLineItem[];
  email: string;

  // optional attributes
  note?: string;
  note_attributes?: Array<{
    [propName: string]: string;
  }>;
  shipping_address?: RechargeAddress;
  shipping_line?: Array<{
    [propName: string]: string;
  }>;
  billing_address?: RechargeAddress;
  discount_code?: string;
  external_checkout_id?: string;
  external_checkout_source?: ExternalCheckoutSource;
  phone?: string;
  buyer_accepts_marketing?: boolean;
}

export interface RechargeCheckoutResponse extends RechargeCheckoutRequest {
  id: string;
  created_at: string;
  shipping_rate: string;
  subtotal_price: string;
  tax_lines: Array<{
    [propName: string]: string;
  }>;
  taxes_included: boolean;
  token: string;
  total_price: string;
  total_tax: string;
  updated_at: string;
  applied_discount?: AppliedDiscount;

  // We need to fill these in for the checkout with update calls.
  payment_processor?: string;
  payment_processor_customer_id?: string;
  payment_processor_transaction_id?: string;
}

enum IntervalUnit {
  day = 'day',
  week = 'week',
  month = 'month'
}
/* API docs re: status
Return the subscriptions with specified status. Status can have the following values:
• ACTIVE - The subscription is active.
• CANCELLED - The subscription has been cancelled.
• EXPIRED - The subscription has been expired. This occurs when the maximum number of charges for a product has been reached.
*/

export enum SubscriptionStatus {
  active = 'ACTIVE',
  cancelled = 'CANCELLED',
  expired = 'EXPIRED'
}

export interface RechargeSubscriptionItem {
  address_id: number;
  cancellation_reason: string | null;
  cancellation_reason_comments: string | null;
  cancelled_at: string | null;
  charge_interval_frequency: number;
  created_at: string;
  customer_id: number;
  expire_after_specific_number_of_charges: number | null;
  has_queued_charges: number;
  id: number;
  is_skippable: boolean;
  is_swappable: boolean;
  max_retries_reached: number; // really boolean: 1: true, 2: false
  next_charge_scheduled_at: string | null;
  order_day_of_month: number | null;
  order_day_of_week: number | null;
  order_interval_frequency: number;
  order_interval_unit: IntervalUnit;
  price: number;
  product_title: string;
  properties: Array<{ name: string; value: string }>;
  quantity: number;
  recharge_product_id: number;
  shopify_product_id: number;
  shopify_variant_id: number;
  sku: string | null;
  sku_override: boolean;
  status: SubscriptionStatus;
  updated_at: string;
  variant_title: string;
}

export interface RechargeAddress {
  id?: number; // how recharge keeps tracks of address,
  // will only be present on already saved address
  address1: string;
  address2?: string;
  city: string;
  province: string;
  first_name: string;
  last_name: string;
  zip: string;
  company?: string;
  phone: string;
  cart_node?: string;
  country: string;
}

export interface RechargeProcessedCheckout {
  checkout_charge: {
    authorization_token: null | string;
    payment_processor_transaction_id: string;
    charge_id: string;
    payment_processor: string;
    payment_token: string;
    payment_processor_customer_id: string;
    status: string;
  };
}

export interface RechargeCustomer {
  id: number;
  hash: string;
  shopify_customer_id: string;
  email: string;
  created_at: string;
  updated_at: string;
  first_name: string;
  last_name: string;
  billing_address1: string;
  billing_address2: null | string;
  billing_zip: string;
  billing_city: string;
  billing_company: null | string;
  billing_province: string;
  billing_country: string;
  billing_phone: string;
  processor_type: 'braintree' | 'stripe';
  status: 'INACTIVE' | 'ACTIVE'; // todo: double check
  analytics_data: {
    [propname: string]: any;
  };
  stripe_customer_token: string;
  has_valid_payment_method: boolean;
  reason_payment_method_not_valid: null | string;
  has_card_error_in_dunning: boolean;
  number_active_subscriptions: number;
  number_subscriptions: number;
  first_charge_processed_at: string;
  braintree_customer_token: string; // we only have one payment processor - this should always be present.
  discount?: {
    code: string;
  };
}

export interface RechargeCustomerCheck {
  id: number;
  email: string;
}

export enum RechargeCustomerPaymentMethod {
  PAYPAL = 'paypal',
  DEBIT = 'debit',
  CREDIT = 'credit',
  CREDIT_CARD = 'credit_card', // used only in Recharge API version 2021-11
  APPLE_PAY = 'apple_pay',
  NO_PAYMENT_METHOD = 'not_set'
}

type RechargeCustomerPaymentType = RechargeCustomerPaymentMethod | null;

export interface RechargePortalPaymentMethod {
  billing_address: {
    address1: string;
    address2: string;
    city: string;
    company: string | null;
    country: string;
    first_name: string;
    last_name: string;
    phone: string;
    province: string;
    zip: string;
  };
  card_brand: string; // todo: use enum
  card_exp_month: number;
  card_exp_year: number;
  card_last4: string;
  cardholder_name: string | null;
  customer_id: number;
  has_card_error_in_dunning: boolean;
  has_valid_payment_method: boolean;
  id: number;
  payment_token: string;
  payment_type: RechargeCustomerPaymentType;
  processor_name: 'stripe' | 'braintree' | 'authorize';
  status: 'inactive' | 'active';
  status_reason: string | null;
}

export interface Subscription {
  quantity: number;
  changed: boolean;
  texture: TextureEnum;
  shortName: string;
}

export interface SubscriptionQuantities {
  [shopifyVariantId: number]: Subscription;
}

export interface RechargeCheckoutPaymentPayload {
  nonce: string;
}

export interface RechargeDiscount extends Discount {
  value: number;
  starts_at: string;
  ends_at: string;
  code: string;
}

export interface RechargeOnetimeItem {
  id: number;
  address_id: number;
  created_at: string; // parseable date
  customer_id: number;
  next_charge_scheduled_at: string; // parseable date
  price: string;
  product_title: string;
  properties: { name: string; value: string }[];
  quantity: number;
  recharge_product_id: number;
  shopify_product_id: number;
  shopify_variant_id: number;
  sku: string;
  status: 'ONETIME';
  updated_at: string; // parseable date
  variant_title: string;
}

export interface RechargeCharge {
  id: number;
  address_id: number;
  created_at: string; // parseable date
  updated_at: string;
  billing_address: RechargeAddress;
  email: string;
  first_name: string;
  last_name: string;
  line_items: RechargeLineItem[];
  shipping_address: RechargeAddress;
  subtotal_price: string;
  tax_lines: string;
  total_line_items_price: string;
  total_price: string;
  total_tax: string;
  type: string;
  shipping_lines: RechargeShippingLine[] | [];
  discounts?: Array<{ code: string }>;
}

export interface RechargeShippingLine {
  code: string;
  description: string;
  price: string;
  source: string;
  last_name: string;
  title: string;
  tax_lines: RechargeTaxLine[] | [];
}

export interface RechargeTaxLine {
  price: string;
  rate: string;
  title: string;
  unit_price: string;
}
