import React, { RefObject } from 'react';
import PageLink from '../components/link';
import { Link } from 'gatsby';
import cx from 'classnames';

export interface BannerBarProps {
  bannerBarText: string;
  bannerBarCTAText?: string;
  bannerBarCTALink?: string;
  bannerBarEnabled: boolean;
  style?: { [propName: string]: string };
  bannerRef?: null | RefObject<HTMLDivElement>;
}

export const BannerBar = ({
  bannerBarText,
  bannerBarEnabled,
  bannerBarCTAText,
  bannerBarCTALink,
  style,
  bannerRef
}: BannerBarProps) => {
  return (
    <div
      className={cx('bannerBar sans f aic jcc', {
        'display: none': !bannerBarEnabled
      })}
      ref={bannerRef}
      style={style}
    >
      <span className="mxa">
        {bannerBarText}{' '}
        {bannerBarCTAText && (
          <Link to={bannerBarCTALink!} target="_blank">
            {bannerBarCTAText}
          </Link>
        )}
      </span>
    </div>
  );
};
