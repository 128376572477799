// @ts-ignore
// @ts-ignore
import googleTagManager from '@analytics/google-tag-manager';
import { ShopifyCart } from 'src/types/shopifyTypes';
// @ts-ignore
import Analytics from 'analytics';
import LogRocket from 'logrocket';
import { generateShopifyCartId } from './checkouts';

// @ts-ignore
import { BRANCH } from '../../../.env.js';
import { QuizEvent } from '../../constants/quiz';
import { TrackingStrings } from '../../constants/analytics';
import {
  stringsToHyphenedStrings,
  stringsToStringWithUnderscores
} from 'src/components/Quiz/panels/utils/quizHelper';
import { Amplitude, TrackingEvent } from '../../utils/tracking';
import _ from 'lodash';

export interface GTMProduct {
  name: string; // ShopifyLineItem product title
  id: string; // SKU!
  price: number | string; // string
  variant: string; // ShopifyLineItem variant title
  trackingPosition?: number | string;
  brand?: string; // "Cat Person" --> leaving as optional to maybe override some day
  category?: string; // "All"
}

// override the @analytics implementation's GTM init -
// we'll do that using gatsby-plugin-google-marketing-platform instead,
// to control the loading of Google Optimize as well
const gtmAnalyticsPlugin = Object.assign(
  googleTagManager({
    containerId: 'GTM-THM9LLT'
  }),
  { initialize: () => ({}) }
);

// Initialize analytics
export const analytics = Analytics({
  app: 'cat-person',
  // @ts-ignore
  version: 100,
  plugins: [gtmAnalyticsPlugin]
});

// Event for tracking Page Views
// Extends analytics.page to provide a named event for GTM
export const trackPageView = () => {
  analytics.page({
    // @ts-ignore
    event: 'viewPage',
    branchName: BRANCH
  });
};

export const identifyUser = (
  email: string,
  rechargeCustomerId: string,
  shopifyCustomerId: string | null
) => {
  analytics.identify(email, {
    rechargeCustomerId,
    shopifyCustomerId
  });
};

// Event for tracking Views of Product Impressions
// Follows Google's Enhanced Ecommerce: https://developers.google.com/tag-manager/enhanced-ecommerce#product-impressions
// Also includes generic event information
export const trackProductImpression = (products: GTMProduct[], list: string) => {
  analytics.track('product_impression', {
    ecommerce: {
      impressions: products.map((product) => ({
        ...product,
        brand: 'Cat Person',
        category: 'All',
        list
      }))
    },
    eventDetail: {
      category: 'Core Ordering',
      action: 'Product Impression',
      label: products.map((product) => product.name).join(', ')
    }
  });
};

// Event for tracking Views of Product Clicks
// Follows Google's Enhanced Ecommerce: https://developers.google.com/tag-manager/enhanced-ecommerce#product-impressions
// Also includes generic event information
export const trackProductClick = (
  products: GTMProduct[],
  list: string,
  position: number,
  additionalDetails?: {
    [propName in 'category' | 'action' | 'label' | 'value']?: any;
  }
) => {
  analytics.track('product_clicked', {
    ecommerce: {
      click: {
        actionField: { list: '' }
      },
      products: products.map((product) => ({
        ...product,
        brand: 'Cat Person',
        category: 'All',
        list,
        position
      }))
    },
    eventDetail: {
      category: 'Core Ordering',
      action: 'Product Clicked',
      label: products.map((product) => product.name).join(', '),
      ...additionalDetails
    }
  });
};

// Event for tracking Views of Product Details
// Follows Google's Enhanced Ecommerce: https://developers.google.com/tag-manager/enhanced-ecommerce#details
// Also includes generic event information
export const trackProductDetailView = (products: GTMProduct[], label: string) => {
  analytics.track('product_detail_viewed', {
    ecommerce: {
      detail: {
        products: products.map((product) => ({
          ...product,
          brand: 'Cat Person',
          category: 'All'
        }))
      }
    },
    eventDetail: {
      category: 'Core Ordering',
      action: 'Product Detail Viewed',
      label
    }
  });
};

export const trackProductListViewed = (
  products: GTMProduct[],
  {
    id,
    category
  }: {
    id: string;
    category: string;
  }
) => {
  analytics.track('product_list_viewed', {
    ecommerce: {
      detail: {
        products: products.map((product) => ({
          ...product,
          brand: 'Cat Person',
          category: 'All'
        }))
      }
    },
    eventDetail: {
      category: 'Core Ordering',
      action: 'Product List Viewed',
      label: `${id}_${category}`
    }
  });
};

// Event for tracking Additions to a Shopping Cart
// Follows Google's Enhanced Ecommerce: https://developers.google.com/tag-manager/enhanced-ecommerce#add
// Also includes generic event information
export const trackAddToCart = (
  product: GTMProduct,
  quantity: number,
  shopifyCart: ShopifyCart,
  category: string
) => {
  analytics.track('product_added', {
    ecommerce: {
      cart_id: generateShopifyCartId(shopifyCart),
      currencyCode: 'US',
      add: {
        products: [{ ...product, quantity, brand: 'Cat Person', category: 'All' }]
      }
    },
    eventDetail: {
      category: 'Core Ordering',
      action: 'Product Added',
      label: `${product.name} -- ${product.variant}`
    }
  });
  const value = stringsToStringWithUnderscores(_.snakeCase(product?.name))(
    _.snakeCase(product?.variant)
  )();
  Amplitude.track(TrackingStrings.ADD_TO_CART, {
    category,
    action: TrackingStrings.ADD_TO_CART,
    value
  } as TrackingEvent);
};

// Event for tracking Additions to a Shopping Cart
// Follows Google's Enhanced Ecommerce: https://developers.google.com/tag-manager/enhanced-ecommerce#add
// Also includes generic event information
export const trackRemoveFromCart = (
  product: GTMProduct,
  quantity: number,
  shopifyCart: ShopifyCart
) => {
  analytics.track('product_removed', {
    ecommerce: {
      currencyCode: 'usd',
      cart_id: generateShopifyCartId(shopifyCart),
      remove: {
        products: [{ ...product, quantity, brand: 'Cat Person', category: 'All' }]
      }
    },
    eventDetail: {
      category: 'Core Ordering',
      action: 'Product Removed',
      label: `${product.name} -- ${product.variant}`
    }
  });
};

// Event for Shopify Cart view
// Not sure this is in enhanced ecommerce but it is in our tracking plan
export const trackCartView = (shopifyCart: ShopifyCart) => {
  analytics.track('cart_viewed', {
    ecommerce: {
      view: {
        cart_id: generateShopifyCartId(shopifyCart),
        products: shopifyCart?.lineItems.map((product) => ({
          name: product.title,
          id: product.id,
          price: parseFloat(product.variant.price),
          brand: 'Cat Person',
          category: 'All',
          variant: product.variant.title,
          quantity: product.quantity
        }))
      }
    },
    eventDetail: {
      category: 'Core Ordering',
      action: 'Cart Viewed',
      label: ``
    }
  });

  Amplitude.track(TrackingStrings.OPEN_CART, {
    category: TrackingStrings.CART
  } as TrackingEvent);
};

export const trackQuizStepViewed = (
  step: string,
  additionalDetails?: {
    [propName in 'category' | 'action' | 'label' | 'value' | 'data']?: any;
  }
) => {
  LogRocket.track(`Quiz Step Viewed - ${step}`);

  analytics.track('quiz_step_viewed', {
    eventDetail: {
      category: 'Quiz',
      action: 'Quiz Step Viewed',
      label: `Quiz Step - ${step}`,
      ...additionalDetails
    }
  });
};

/* Fetch a Shopify customer record, and populate a customer object
in the dataLayer. Used for affiliate marketing in our Recharge checkout:
we can see if an a la carte customer is now a subscription customer. */

export const fetchShopifyRecordAffiliateTrackingInformation = async (email: string) => {
  // LogRocket.track(`Quiz Step Answered - ${step}`)

  const customerResponse = await fetch(
    `${process.env.GATSBY_API_URL}/functions/affiliate-tracking`,
    {
      method: 'post',
      body: JSON.stringify({ customer_email: email })
    }
  );

  return customerResponse?.json();
};

export const trackQuizStepAnswered = async (step: string, data: any) => {
  LogRocket.track(`Quiz Step Answered - ${step}`);

  let customer;

  if (step === 'email' && data?.email) {
    customer = await fetchShopifyRecordAffiliateTrackingInformation(data.email);
  }

  analytics.track('quiz_step_answered', {
    eventDetail: {
      category: 'Quiz',
      action: 'Quiz Step Answered',
      label: `Quiz Step - ${step}`,
      data
    },
    customer
  });
};

export const trackCrossSell = (
  location: 'in_cart',
  product: GTMProduct,
  additionalDetails?: {
    [propName in 'category' | 'action' | 'label' | 'value']: any;
  }
) => {
  LogRocket.track(`In-Cart Cross Sell, Add To Cart`);

  let eventName;
  if (location === 'in_cart') {
    eventName = 'in_cart_cross_sell_add_to_cart';
  } else {
    eventName = 'cross_sell_add_to_cart_LOCATION_NOT_SPECIFIED';
  }

  analytics
    .track(eventName, {
      eventDetail: {
        category: 'Cross Sell',
        action: `In-Cart Add To Cart`,
        label: `${product.name} -- ${product.variant}`,
        ...additionalDetails
      }
    })
    .then();
};

export const trackOfferView = (cohort: string) => {
  LogRocket.track(`Offer View - ${cohort}`);

  analytics.track('offer_view', {
    eventDetail: {
      category: 'Offer Testing',
      action: 'Offer Viewed',
      label: `Offer Viewed - ${cohort}`,
      value: cohort
    },
    userCohort: cohort
  });
};

// Event for tracking Generic Events
// Structure follows expected values for Google Analytics
export const eventTracked = (category: string, action: string, label: string, value?: number) => {
  analytics.track('event_tracked', {
    eventDetail: {
      category,
      action,
      label,
      value
    }
  });
};

export const trackQuizEvent = ({ name, category, action, label, value }: QuizEvent) => {
  const logRocketData = stringsToHyphenedStrings(category)(action)(label);
  LogRocket.track(logRocketData);
  analytics.track(name, {
    eventDetail: {
      category,
      action,
      label,
      value
    }
  });
};

export const trackLargeMealPlanModalShown = async () => {
  analytics.track('modal_opened', {
    modalType: 'too_much_food'
  });
};

export const trackAccountPortalPromoApplied = (itemName: string, sku: string) => {
  analytics.track('ap_promo_applied', {
    eventDetail: {
      category: 'account_portal',
      action: 'promo_applied',
      label: itemName,
      value: sku
    }
  });
};

export default analytics;
