// Event for Google Enhanced Ecommerce Checkout
import { ShopifyCart } from 'src/types/shopifyTypes';
import { analytics } from './analytics';
import * as handleItems from 'src/integrations/shopify/handleShopifyLineItems';
import { RechargeCheckoutResponse } from 'src/types/rechargeTypes';
import LogRocket from 'logrocket';
import sha256 from 'crypto-js/sha256';
import { v4 as uuidv4 } from 'uuid';
import lastPathComponent from 'src/utils/lastPathComponent';

const uuid = uuidv4();

export const generateShopifyCartId = (shopifyCart: ShopifyCart) => {
  return (shopifyCart?.id && lastPathComponent(shopifyCart?.id)) || uuid;
};

export const trackRechargeCheckoutStarted = (shopifyCart: ShopifyCart) => {
  LogRocket.track(`Recharge Checkout Started`);

  const samplerBoxItems = shopifyCart?.lineItems?.filter(handleItems.samplerBoxItems) || [];
  const oneOffItems = shopifyCart?.lineItems?.filter(handleItems.oneOffItems) || [];

  const trackedItems = [...oneOffItems, ...samplerBoxItems];

  const revenue = trackedItems.reduce((acc, item) => acc + parseFloat(item.variant.price), 0);

  analytics.track('checkout_started', {
    ecommerce: {
      checkout: {
        /* order_id not generated until checkout completion */
        checkout_id: generateShopifyCartId(shopifyCart),
        revenue,
        currency: 'usd',
        products: trackedItems.map((product) => ({
          name: product.title,
          id: product.variant.sku,
          price: parseFloat(product.variant.price),
          brand: 'Cat Person',
          category: 'All',
          variant: product.variant.title,
          quantity: product.quantity
        }))
      }
    },
    eventDetail: {
      category: 'Recharge Checkout',
      action: 'Recharge Checkout Started',
      label: 'Recharge'
    }
  });
};

export const trackRechargeCheckoutPaymentMethodSelection = (
  checkoutOption: 'paypal' | 'credit-card' | 'applepay'
) => {
  analytics
    .track('checkout_option', {
      ecommerce: {
        checkout_option: {
          actionField: {
            option: checkoutOption
          }
        }
      },
      eventDetail: {
        category: 'EnhancedEcommerce',
        action: 'checkoutOption',
        label: 'Recharge'
      }
    })
    .then();
};

export const trackRechargeCheckoutCompleted = (
  shopifyCart: ShopifyCart,
  rechargeCart: RechargeCheckoutResponse,
  id: string | number
) => {
  LogRocket.track(`Recharge Checkout Completed`);

  const email = shopifyCart.email ?? '';
  const emailSha256 = sha256(email).toString();

  analytics.track('purchase', {
    ecommerce: {
      purchase: {
        actionField: {
          id,
          affiliation: 'Recharge Checkout',
          revenue: parseFloat(rechargeCart.subtotal_price || ''),
          tax: parseFloat(rechargeCart.total_tax || ''),
          shipping: parseFloat(rechargeCart.shipping_rate || ''),
          emailSha256
          // coupon: ''
        },
        products: rechargeCart?.line_items?.map((product) => ({
          name: product.title,
          id: product.sku,
          price: parseFloat(product.price || ''),
          brand: 'Cat Person',
          category: 'All',
          variant: product.variant_title,
          quantity: product.quantity
        }))
      }
    },
    eventDetail: {
      category: 'EnhancedEcommerce',
      action: 'Purchase',
      label: 'Recharge'
    }
  });

  analytics.track('checkout_completed', {
    ecommerce: {
      checkout: {
        order_id: id,
        checkout_id: generateShopifyCartId(shopifyCart),
        subtotal: parseFloat(rechargeCart.subtotal_price || ''),
        total: parseFloat(rechargeCart.total_price || ''),
        revenue: parseFloat(rechargeCart.subtotal_price || ''),
        value: parseFloat(rechargeCart.subtotal_price || ''),
        tax: parseFloat(rechargeCart.total_tax || ''),
        shipping: parseFloat(rechargeCart.shipping_rate || ''),
        currency: 'usd',
        emailSha256,
        products: rechargeCart?.line_items?.map((product) => ({
          name: product.title,
          id: product.sku,
          price: parseFloat(product.price || ''),
          brand: 'Cat Person',
          category: 'All',
          variant: product.variant_title,
          quantity: product.quantity
        }))
      }
    },
    eventDetail: {
      category: 'Recharge Checkout',
      action: 'Checkout Completed',
      label: 'Recharge',
      value: rechargeCart.subtotal_price
    }
  });
};

export const trackCheckoutStepViewed = (shopifyCart: ShopifyCart, step: string) => {
  analytics.track('checkout_step_viewed', {
    ecommerce: {
      checkout: {
        checkout_id: generateShopifyCartId(shopifyCart),
        step
      }
    },
    eventDetail: {
      category: 'Recharge Checkout',
      action: 'Checkout Step Viewed',
      label: 'Recharge'
    }
  });
};

export const trackCheckoutStepCompleted = (shopifyCart: ShopifyCart, step: string) => {
  analytics.track('checkout_step_completed', {
    ecommerce: {
      checkout: {
        checkout_id: generateShopifyCartId(shopifyCart),
        step
      }
    },
    eventDetail: {
      category: 'Recharge Checkout',
      action: 'Checkout Step Completed',
      label: 'Recharge'
    }
  });
};

export const trackPaymentInfoEntered = (shopifyCart: ShopifyCart, step: string) => {
  analytics.track('payment_info_entered', {
    ecommerce: {
      checkout: {
        checkout_id: generateShopifyCartId(shopifyCart),
        /* order_id not generated until checkout completion */
        step
      }
    },
    eventDetail: {
      category: 'Recharge Checkout',
      action: 'Payment Info Entered',
      label: 'Recharge'
    }
  });
};
