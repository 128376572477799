import { ShopifyLineItem } from 'src/types/shopifyTypes';
import { PurchaseType, TextureEnum } from 'src/types/types';
import lastPathComponent from 'src/utils/lastPathComponent';

export interface SimpleRechargeItem {
  variant_id: number;
  quantity: number;
  price: string;
  properties: any;
}

export const convertShopifyItemToRechargeItem = (
  lineItem:
    | ShopifyLineItem
    | { title: string; quantity: number; variant: { id: string; sku: string } }
): SimpleRechargeItem => {
  return {
    variant_id: parseInt(lastPathComponent(lineItem.variant.id)),
    quantity: lineItem.quantity
  };
};
