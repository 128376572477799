import { dryFlavorSKUs, wetFlavorSKUs } from '../../constants/flavorAndProductSKUs';
import { ShopifyCart, ShopifyLineItem } from 'src/types/shopifyTypes';
import { samplerBoxItems } from './handleShopifyLineItems';

const STARTING_SUM = 0;

const cartItemToQuantity = (cartItem: ShopifyLineItem): number => cartItem.quantity;

export const numbersToSum = (number1 = 0, number2 = 0): number => number1 + number2;

export const isDryItem = (sku: string) => dryFlavorSKUs.includes(sku);

const isDryLineItem = (item: ShopifyLineItem): boolean => isDryItem(item.variant.sku);

export const isWetItem = (sku: string) => wetFlavorSKUs.includes(sku);

const isWetLineItem = (item: ShopifyLineItem): boolean => isWetItem(item.variant.sku);

export const verifySamplerBox = (shopifyCart: ShopifyCart | undefined | null) => {
  const samplerBoxLineItems = shopifyCart?.lineItems?.filter(samplerBoxItems) ?? [];
  const dryItems: ShopifyLineItem[] = samplerBoxLineItems?.filter(isDryLineItem);
  const dryItemQuantities: number[] = dryItems.map(cartItemToQuantity);
  const dryItemQty: number = dryItemQuantities.reduce(numbersToSum, STARTING_SUM);
  const wetItems: ShopifyLineItem[] = samplerBoxLineItems?.filter(isWetLineItem);
  const wetItemQuantities: number[] = wetItems.map(cartItemToQuantity);
  const wetItemQty: number = wetItemQuantities.reduce(numbersToSum, STARTING_SUM);
  const hasWetAndDryStarterBox: boolean = dryItemQty >= 1 && wetItemQty >= 2;
  const hasWetStarterBox: boolean = wetItemQty >= 3;
  const hasDryStarterBox: boolean = dryItemQty >= 2;
   return hasWetAndDryStarterBox || hasWetStarterBox || hasDryStarterBox;
};
