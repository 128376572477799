import * as handleItems from 'src/integrations/shopify/handleShopifyLineItems';
import { ShopifyCart } from 'src/types/shopifyTypes';

export const deriveCartQuantity = (shopifyCart: ShopifyCart | undefined) => {
  let itemCount = 0;

  /* Treat sample box like a single item */

  const samplerBoxItems = shopifyCart?.lineItems?.filter(handleItems.samplerBoxItems);

  if (samplerBoxItems && samplerBoxItems.length > 0) itemCount++;

  /* Treat a subscription like a single item */

  const subscriptionItems = shopifyCart?.lineItems?.filter(handleItems.subscriptionItems);

  if (subscriptionItems && subscriptionItems.length > 0) itemCount++;

  /* Handle the rest of the items normally */

  const oneOffItems = shopifyCart?.lineItems?.filter(handleItems.oneOffItems);
  const oneOffItemQuantities = oneOffItems?.reduce((acc, item) => acc + item?.quantity, 0) || 0;

  itemCount += oneOffItemQuantities;

  return itemCount;
};
