import { init, track, Identify, identify } from '@amplitude/analytics-browser';
import _ from 'lodash';
import LogRocket from 'logrocket';
import { stringsToStringWithUnderscores } from '../components/Quiz/panels/utils/quizHelper';
import { TrackingStrings } from '../constants/analytics';
import cookie from 'js-cookie';

export interface TrackingEvent<T> {
  category: string; // Module/Component, (ex: "Home", "Footer", "Cart", "Quiz - Cat Names")
  action?: string; // Event trigger (ex: "Product Add to Cart", "Quantity Increase", "Viewed", "Value Entered")
  label?: string; // Button label clicked, if any (ex: "Get Started", "Checkout")
  value?: T; // Context (ex: "Beds - Tundra", 4, {type: "wet", name: "Tuna Pate"})
}

let dataSharingOptOut = false;
if (typeof window !== 'undefined') {
  // @ts-ignore globalPrivacyControl not available yet in browser API types
  dataSharingOptOut = cookie.get('cp_data_opt_out') || navigator.globalPrivacyControl;
}

const KEY = process.env.GATSBY_AMPLITUDE_KEY || '';
const actions = {
  init: () => {
    init(KEY);
  },
  track: async (name, props) => {
    if (dataSharingOptOut) {
      return;
    }

    try {
      init(KEY);
      track(name, props);
      // console.log(`Amplitude Tracking - '${name}'\n${JSON.stringify(props, null, 4)}`);
    } catch (err) {
      LogRocket.captureException(err as Error);
    }
  }
};
export const Amplitude = actions;

export const addTrackingEvent = async (
  eventName: string,
  category: string,
  value: string,
  actionTaken: string,
  combine: boolean = true
) => {
  value = _.snakeCase(value);
  const action = (combine && stringsToStringWithUnderscores(value)(actionTaken)()) || actionTaken;
  const trackingEvent: TrackingEvent<string> = {
    category,
    action,
    value
  };
  Amplitude.track(eventName, trackingEvent);
};

export const addClickTrackingEvent = async (eventName: string, category: string) => {
  return addTrackingEvent(eventName, category, '', TrackingStrings.CLICKED, false);
};
