module.exports.meta = `
  metaInfo {
    _type == 'metaCard' => {
      ...,
      'openImageUrl': openImage.asset->url,
      'twitterImageUrl': twitterImage.asset->url
    }
  }
`;

module.exports.quizv2 = `*[_type == "quizSettings" && _id == "generalQuizSettings"][0] {
  ...,
  "review": *[_type == "revampedQuizReviewPanel" && _id == "reviewPanel"][0] {
    ...,
    "products": *[_type == "product"] {
      ...,
      title,
      variants[]-> {
        ...,
        variantDescription,
        variantTitle,
        variantSubTitle,
        variantPdpSubTitle,
        title,
        variantCleanTitle,
        variantFoodType,
        variantLifeStyleImage, 
        variantImage,
        image,
        whatsInside,
        "ingredientsAccordion": variantAccordionItems.accordion[title == "Ingredients"][0] {
          details,
          content,
          "illustration": image.asset->
        }
      }
    },
    "FAQ": {
      "header": *[_id == "misc"][0]["quizAccordionHeader"],
      "accordionItems": *[_id=="misc"][0]["quizAccordionItems"],
      "image": *[_id=="misc"][0]["quizReviewImage"]
    },
  },
}
`;

const createModuleQuery = (set = '') => {
  return `${set}[] {
  ...,
  _type == 'featureModule' => {
    _key,
    _type,
    icons[] {
      ...,
      'imageId': icon.asset._ref
    }
  },
  _type == 'singleImage' => {
    _key,
    _type,
    imageAlt,
    'imageId': image.asset._ref
  },
  _type == 'imageAndText' => {
    _key,
    _type,
    ...,
    imageAlt,
    'imageId': image.asset._ref
  },
  _type == 'productInside' => {
    _key,
    _type,
    showInside
  },
  _type == 'dynamicPromise' => {
    _key,
    _type,
    text
  },
  _type == 'simpleCTA' => {
    ...,
    linkedTo-> {
      _type,
      slug
    }
  },
  _type == 'instagramModule' => {
    ...,
    instas[] {
      ...,
      'imageId': image.asset._ref
    }
  },
  _type == 'marquee' => {
    ...,
    text[] {
      ...,
      _type == 'inlineIcon' => {
        _key,
        'image': asset->url,
        'imageId': asset._ref
      }
    }
  },
  _type == 'scrollableInfo' => {
    ...,
    'imageId': image.asset._ref,
    infoBlocks[] {
      ...,
      'imageId': icon.asset._ref
    }
  },
  _type == 'heroModule' => {
    ...,
    linkedTo-> {
      _type,
      slug
    },
    images[] {
      ...,
      'imageId': image.asset._ref
    }
  },
  _type == 'parallaxHero' => {
    ...,
    linkedTo-> {
      _type,
      slug
    },
    'imageId': image.asset._ref,
    images[] {
      ...,
      'imageId': asset._ref
    }
  },
  _type == 'primaryHeroModule' => {
    ...,
    slides[] {
      ...,
      'imageId': image.asset._ref,
      linkedTo-> {
        _type,
        slug
      }
    },
    mobileAlt {
      ...,
      'imageId': asset._ref
     }
  },
  _type == 'shopProducts' => {
    ...,
    products[] {
      ...,
      variants[]-> {
        ...,
        _rev,
        'lifestyleImageId': variantLifeStyleImage.asset._ref,
        'imageId': variantImage.asset._ref,
        'imageBoxId': variantBoxImage.asset._ref,
        variantCleanTitle,
        variantSubTitle,
        variantPdpSubTitle,
        cardInfoTitle,
        cardInfoDescription,
        slug,
        hidden,
        blackCTA
      }
    }
  },
  _type == 'shopFood' => {
    title,
    columns,
    products[] {
      hidden,
      'imageId': image.asset._ref,
      'imageAlt': image.alt,
      linkTitle,
      link,
      'slug': reference->slug,
      'product': reference->{
        title,
        sku,
        variantTitle,
        blackCTA,
        variantPath,
        variantQueryParam,
        collectionSlug,
      }
    }
  },
  _type == 'selectStarterBox' => {
    ...,
    mobileImages[] {
      'imageId': image.asset._ref,
       linkedTo-> {
        _type,
        slug
      }
    },
    desktopImages[] {
      'imageId': image.asset._ref,
       linkedTo-> {
        _type,
        slug
      }
    },
    starterBoxes[] {
      ...,
      desktopImage,
      mobileImage,
      checkedCheckmark,
      uncheckedCheckmark
    },
    additionalInformation[] {
      ...,
      iconImage {
        ...,
        "asset": asset->
      }
    }
  },
  _type == 'starterBoxHowItWorks' => {
    ...,
    details[] {
      ...,
      iconImage {
        ...,
        "asset": asset->
      }
    }
  },
  _type == 'pressGrid' => {
    ...,
    press[] {
      ...,
      'iconId': iconImage.asset._ref,
      'catId': catIcon.asset._ref
    }
  },
  _type == 'splitModule' => {
    _key,
    _type,
    alignment,
    linkedCTA,
    linkedTo-> {
      _type,
      slug
    },
    'splitImageId': splitImage.asset._ref,
    subtitle,
    text[] {
      ...,
      children[] {
        ...,
        _type == 'image' => {
          'asset': asset->,
          'image': asset->url
        }
      }
    }
  },
  _type == 'accordionItems' => {
    ...,
    'imageId': image.asset._ref,
  },
  _type == 'standardFAQ' => {
    ...,
    accordionItems[] {
      ...
    },
    image->,
  },
  _type == 'testimonials' => {
    ...
  },
  _type == 'satisfactionGuarentee' => {
    ...,
    'imageId': image.asset._ref,
  },
  _type == 'relatedProducts' => {
    ...,
    products[]-> {
      ...,
      _rev,

      variantImage,
      'variantImageId': variantImage.asset._ref,
      variantBoxImage,
      'variantBoxImageId': variantBoxImage.asset._ref,
      'variantLifestyleImage': variantLifeStyleImage,
      'variantLifestyleImageId': variantLifeStyleImage.asset._ref,
     
      'productLifestyleImage': productLifeStyleImage,
      'productLifestyleImageId': productLifeStyleImage.asset._ref,
      productPackageImage,
      'productPackageImageId': productPackageImage.asset._ref,
      
      variants[]->,

      sku,
      title,
      variantTitle,
      variantCleanTitle,
      variantSubTitle,
      variantPdpSubTitle,
      cardInfoTitle,
      cardInfoDescription,
      slug,
      hidden
    }
  },
  _type == 'featuredProduct' => {
    ...,
    'splitImageId': splitImage.asset._ref,
    'arcTextId': arcText.asset._ref,
    associatedProduct-> {
      _type,
      title,
      slug,
      variantSelection,
      variants[]-> {
        _id,
        _type,
        title,
        variantFoodType,
        variantColor,
        variantPath,
        variantQueryParam,
        collectionSlug,
        sku, 
        'variantImageId': variantImage.asset._ref,
        'imageBoxId': variantBoxImage.asset._ref,
        color,
        variantTitle,
        hidden,
        blackCTA
      }
    }
  },
  _type == 'standardText' => {
    ...,
    whiteOnBlack
  },
  _type == 'videoModule' => {
    ...,
    fullBleed,
    title,
    videoURL
  },
  _type == 'flavorModule' => {
    ...,
    sections[] {
      tabLists[] {
        tabs[],
				tabPanels[] {
      		productVariants[]->{
            ...,
              'ingredientsAccordion': variantAccordionItems.accordion[title == "Ingredients"][0] {
              details,
              content,
              'illustration': image.asset->
            }
          }  
        }
      },
      ...,
      productVariants[]->{
          ...,
            'ingredientsAccordion': variantAccordionItems.accordion[title == "Ingredients"][0] {
            details,
            content,
            'illustration': image.asset->
          }
        }  
    }
  },
}`;
};

module.exports.modules = createModuleQuery('modules');
module.exports.collectionModules = createModuleQuery('collectionModules');

module.exports.productQuery = `
  ...,
  ${module.exports.meta},
	title,
	slug,
  variantSelection,
  productTitle,
  productSubtitle,
  descriptionB,
  variantSelection,
  defaultVariant,
  description,
  showCarouselNavigation,
  showSubscriptionInfo,
  useProductNameOnPDP,
  hideOOSNotification,
  productMedia[] {
    _type == 'image' => {
      _key,
      _type,
      'imageId': asset._ref
    }
  },
  'whatsInside': variants[]-> {
    ...,
    whatsInside[] {
      ...,
      'iconId': icon.asset._ref,
    },
  },
  'nestedAccordions': variants[]-> {
    ...,
    variantAccordionItems {
      ...,
      'imageId': image.asset._ref
    },
  },
  'nestedIngredients': variants[]-> {
    ...,
    'photoId': ingredientsPhoto.asset._ref,
    'photoIdMobile': ingredientsPhotoMobile.asset._ref,
    ingredientsIntro,
    whatsInside[] {
      ...,
      'iconId': icon.asset._ref,
    }
  },
  'variantImages': variants[]-> {
    ...,
    variantCleanTitle,
    variantFoodType,
    'images': variantImages[] {
      ...,
      _type == 'image' => {
        ...,
        primaryCarouselImage,
        _key,
        _type,
        'imageId': asset._ref
      }
    }
  },
  variants[]-> {
    _key,
    _type,
    title,
    variantPath,
    collectionSlug,
    variantQueryParam,
    variantFoodType,
    variantCleanTitle,
    variantSubTitle,
    variantPdpSubTitle,
    variantTitle,
    variantBadge,
    variantDescription,
    variantColor,
    variantImage,
    variantBoxImage,
    variantLifeStyleImage,
    'variantPromo':  {
      'promoCode': variantPromoCode,
      'message': variantPromoMessage,
      'freeItemVariant': variantPromoFreeProductVariant-> {
        _type,
        sku
      },
      'imageId': variantPromoImage.asset._ref
    },
    hidden,
    klaviyoList,
    variantAccordionItems {
      ...,
      'imageId': image.asset._ref
    },
    sku,
    variantLifeStyleImage {
      asset->,
      ...
    },
    'variantImageId': variantImage.asset._ref,
    'imageBoxId': variantBoxImage.asset._ref,
    'variantLifestyleImageId': variantLifeStyleImage.asset._ref,
    'overrideShopifyAvailability': *[_type == "misc"][0]["overrideShopifyAvailability"][references(^._id)]->,
    blackCTA
  },
  ${module.exports.modules},
`;

module.exports.products = `*[_type == "product"] {
  ${module.exports.productQuery}
}`;

module.exports.collections = `*[_type == "collection" && slug.current in ["products", "wet-food", "dry-food", "treats", "toys", "accessories", "apparel", "bundles"]]{
  _rev,
  ${module.exports.meta},
  title,
  slug,
  'imageId': mainImage.asset._ref,
  text,
  modules[] {
    ...,
    '_key': _rev,
    'productPackageImage': productPackageImage->,
    defined(_ref) => @->{
      ...,
      'overrideShopifyAvailability': *[_type == "misc"][0]["overrideShopifyAvailability"][references(^._id)]->,
      'imageId': variantImage.asset._ref,
      variants[]->
    },
    _type == 'ctaCard' => {
      ...,
      'imageId': icon.asset._ref,
      linkedTo-> {
        _type,
        slug
      }
    }
  },
  ${module.exports.collectionModules}
}`;

module.exports.menus = `*[_type == "menus"]{
  ...,
  slug,
  'menuSlug': slug.current,
  title,
  items[] {
		_type == 'externalLink' => {
      _key,
      _type,
      link,
      title
    },
    _type == 'ccpaOptOutLink' => {
      ...
    },
    defined(_ref) => @->{
      _type,
      '_key': _rev,
      slug,
      title
    }
  }
}`;

module.exports.footer = `*[_type == "footer"][0]{
 ...
}`;

module.exports.misc = `*[_type == "misc"][0]{
  ...,
  miscProduct[] {
    ...,
    iconImage {
      ...,
      "asset": asset->
    }
  },
  cartUpsells[]-> {
    ...,
    variantImage {
      ...,
      asset->
    }
  },
  text[] {
    ...,
    children[] {
      ...,
      "asset": asset->
    }
  },
  misc[] {
    ...,
    "mobileMenuBackgroundImage": mobileMenuBackgroundImage->
  },
  overrideShopifyAvailability[]-> {
    ...
  }
}`;

module.exports.bannerBar = `*[_type == "bannerBar"][0]{
  bannerBarEnabled,
  bannerBarText,
  bannerBarCTAText,
  bannerBarCTALink
}`;

module.exports.pageQuery = `
  ${module.exports.meta},
  title,
  slug,
  ${module.exports.modules},
  ${module.exports.quiz}
`;

module.exports.pages = `*[_type == "page"] {
  ${module.exports.pageQuery}
}`;

module.exports.discountCodes = `*[_type == "discountCode"]{
  code,
  wet_dry_code,
  wet_only_code,
  dry_only_code,
  sb_promo_sku
}`;

module.exports.discountCodesByCode = (code) => `*[_type == "discountCode" && code == "${code}"]{
  code,
  wet_dry_code,
  wet_only_code,
  dry_only_code
}`;

module.exports.starterBoxes = `*[_type == "page" && slug.current == "meal-plan"]{
  title,
  slug,
  modules[0]{
    _type == 'selectStarterBox' => {
    starterBoxes[] {
      originalPrice,
      cardTitle
    }
  }
  }
}`;

module.exports.accountPortal = `*[_type == "promos"] | order(order asc) {
  _type,
  name,
  isActive,
  title,
  description,
  skus,
  image,
  price,
  discountedPrice,
  buttonText,
  discountCode,
  isSubscription,
  isLimitOnePerOrder,
  quantityLimit,
  useLeftImageLayout,
  hideIfSubscribedToProduct,
  promoModalTitle,
  promoModalSubtitle,
  promoModalVariants[]-> {
    variant-> {
      _type,
      slug,
      sku,
      title,
      'variantBoxImageId': variantBoxImage.asset._ref,
      'variantMainImageId': variantImage.asset._ref,
    },
    description,
    unitInfo  
  }
}`;
