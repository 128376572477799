export const foodNav = {
  className: 'foodNav',
  header: 'Shop Food',
  items: [
    {
      route: '/meal-plan',
      text: 'Starter Box',
      className: 'h2 cooper mealPlan'
    },
    {
      route: '/products/wet-food',
      text: 'Wet Food',
      className: 'h2 cooper wetFood'
    },
    {
      route: '/products/dry-food',
      text: 'Dry Food',
      className: 'h2 cooper dryFood'
    },
    {
      route: '/products/treats',
      text: 'Treats',
      className: 'h2 cooper treats'
    },
    {
      route: '/products/accessories',
      text: 'Accessories',
      className: 'h2 cooper accessories'
    },
    {
      route: '/products/toys',
      text: 'Toys',
      className: 'h2 cooper toys'
    },
    {
      route: '/products/apparel',
      text: 'Apparel',
      className: 'h2 cooper apparel'
    },
    {
      route: '/products',
      text: 'Shop All',
      className: 'h2 cooper shop-all'
    }
  ]
};

export const companyNav = {
  className: 'companyNav',
  header: 'Company',
  items: [
    {
      route: '/how-it-works',
      text: 'How It Works',
      className: 'h2 cooper howItWorks'
    },
    {
      route: '/food-philosophy',
      text: 'Food Philosophy',
      className: 'h2 cooper foodPhilosophy'
    },
    {
      route: '/help-and-faqs',
      text: 'Help & FAQ',
      className: 'h2 cooper helpAndFAQs'
    }
  ]
};
