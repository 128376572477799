import { isStringAndStringEqual } from 'src/components/Quiz/panels/utils/quizHelper';
import { numberToDailyRate, numberToDiscountRate } from 'src/utils/transformations';
import * as handleItems from 'src/integrations/shopify/handleShopifyLineItems';
import { verifySamplerBox } from 'src/integrations/shopify/verifySamplerBox';
import { ShopifyCart, ShopifyLineItem } from 'src/types/shopifyTypes';
import { DEFAULT_OFFER } from 'src/constants/prices';
import { StarterType } from 'src/constants/Bundles/options/bundleOptions';
import { WET } from 'src/constants/texture';

export const calculateTotals = (
  shopifyCart: ShopifyCart,
  purchasedSamplerBox: boolean,
  starterBoxType: StarterType | undefined,
  offerPrice: number | undefined,
  shippingRate: number | null,
  _: string
) => {
  const lineItems = shopifyCart?.lineItems ? shopifyCart.lineItems : [];
  const defaultOfferPrice =
    starterBoxType === WET ? DEFAULT_OFFER.OFFER_PRICE_WET : DEFAULT_OFFER.OFFER_PRICE_WET_AND_DRY;
  const samplerBoxTotal =
    verifySamplerBox(shopifyCart) || purchasedSamplerBox
      ? offerPrice || defaultOfferPrice
      : defaultOfferPrice;

  const oneOffItems = lineItems.filter(handleItems.oneOffItems);
  const oneOffTotal = oneOffItems.reduce(
    (acc, item) => Number(item.variant.price.amount) * Number(item.quantity) + acc,
    0
  );

  const preRechargeSubtotal = samplerBoxTotal + oneOffTotal;

  const subscriptionItems = lineItems.filter(handleItems.subscriptionItems);

  const subscriptionTotalWithoutDiscount = subscriptionItems
    .reduce((acc, item) => acc + Number(item.quantity) * Number(item.variant.price.amount), 0);
  const subscriptionTotalWithDiscount = numberToDiscountRate(subscriptionTotalWithoutDiscount);

  return {
    oneOffTotal,
    preRechargeSubtotal,
    subscriptionTotalWithDiscount,
    subscriptionTotalWithDiscountPerDay: numberToDailyRate(
      subscriptionTotalWithDiscount + (shippingRate ?? 0)
    ),
    subscriptionTotalWithoutDiscount
  };
};
